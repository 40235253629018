@import "../global/app.baltros.white/variables";

body {
    $login-background-color: #f4f4f4;
    background-color: $login-background-color;
}


.brc-div-auth {
    $auth-wrapper-width: 350px;
    $padding-auth-block: 12px;
    //position: absolute;
    width: $auth-wrapper-width;
    /*top: 25%;
    left: 50%;
    margin-left: -($auth-wrapper-width / 2);*/
    background-color: $content-wrapper-color;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4);
    padding: $padding-auth-block;

    .brc-login-title {
        font-weight: 300;
        color: $primary-color;
        margin: 0;
        margin-bottom: 10px;
        line-height: 1.5;
    }

    .input-group {
        width: 100%;
        label {
            display: block;
            font-weight: bold;
            font-size: 12px;
            color: #444;
        }
        .k-textbox {
            font-size: 14px;
            width: 100%;
        }
    }

    .form-actions {
        margin: -$padding-auth-block;
        margin-top: $padding-auth-block * 2;
        .brc-login-button {
            height: 50px;
            width: 100%;
            border-radius: 0;
            background-color: $button-color;
            border: 0;
            color: $button-text-color;
            font-size: 20px;
            font-weight: 300;
            i {
                font-size: 15px;
            }
            &:hover {
                background-color: $button-hover-color;
            }
        }
    }
}

.brc-login-summary {
    ul {
        padding-left: 0;
        list-style: none;
        background-color: #fef7f8;
        border-left: 5px solid #f0868e;
        padding: 15px 10px;
    }
}

body{
            
            margin: auto;
            padding-top:30px;
        }
        h1,h2,h3,h4{
            margin-top:5px;
        }
        .brc-div-container{
            display: flex;

            width:100%;
        }
        .brc-div-auth {
            flex:1;
            position: relative !important;
            width: 350px;
            top: 0;
            right: 0;
            margin-left: 0;
            background-color: #fff;
            box-shadow: 0 2px 3px rgba(0,0,0,.4);
            padding: 12px;
        }
        .brc-div-about {
            flex:2;
            width:100%;
            font-size: 14px;
            margin-right:30px;
            background-color: #fff;
            box-shadow: 0 2px 3px rgba(0,0,0,.4);
            padding: 12px;
        }
        .brc-div-personal,.brc-div-news{
            width:100%;
            margin-top:30px;
            background-color: #fff;
            box-shadow: 0 2px 3px rgba(0,0,0,.4);
            padding: 12px;
        }
        .brc-div-personal{
            width:min-content;
        }

        .brc-best-personal__wrapper{
            display: grid; 
            grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
        }
        .brc-best-personal__wrapper div{
            width: 100%;
            padding: 10px;
            margin: auto;
            text-align: center;
        }

        .brc-news__wrapper{
            display: grid; 
            grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
        }
        .brc-news-item{
            width: 100%;
            padding: 10px;
            margin: auto;
            /*border: 1px solid #f0f0f0;*/
            font-size: 14px;
            height: 100%;
        }
        .brc-news-item__stat-info{
            color:gray;
            text-align: right;
            border-top: 1px solid #f0f0f0;
            padding:10px 0;
        }

        .brc-news-item__header{
            margin:10px 0;
        }

        .brc-login-button {
            bottom: 0;
            position: absolute;
        }
        .brc-personal-and-documents{
            display: flex;
            flex-direction: row;
        }
        .brc_best-personal__wrapper{
            flex: 0; 
            width: min-content;
        }
        .brc-documents__wrapper{
            flex: 1;
            margin-left: 30px;
            display: flex;
            align-items: stretch;
        }
        .brc-photo-data{
            display: flex;
            flex-direction: row;
        }
        .brc-photo__wrapper{
            flex: 2; 
        }
        .brc-data__wrapper{
            flex: 1;
            margin-left: 15px;
            align-items: stretch;
            position: relative;
        }
        .brc-data__book{
            position: absolute;
            bottom: 0;
        }

        @media(max-width: 450px){
            body{
                padding-top: 0;
            }

            .brc-div-container{
                flex-direction: column;
            }

            .brc-photo-data{
                flex-direction: column;
            }

            .brc-personal-and-documents{

                flex-direction: column-reverse;
            }

            .brc-div-auth{
                width: 100%;
                margin-top: 30px;
            }

            .brc-data__wrapper{
                margin-left: 0;
                margin-top: 15px;
            }
            .brc-data__book{
                padding-top: 15px;
                position: relative;
            }

            .brc-login-button{

                position: relative;

            }
            .brc-documents__wrapper{
            margin-left:0;
            margin-top:15px;
            }

            .brc_best-personal__wrapper,.brc-div-personal{
            width:100%;
            margin-top:15px;
            }

            #bookPesonalModal .modal-dialog{
            width:auto;
            }

}