﻿$body-background-color: #efefef;

$primary-color: #0072c6;
$active-primary-color: #f4f4f4;//#2a8dd4;
$primary-service-color: #f4f4f4;
$active-primary-service-color: #eaeaea;

$blue-gray-primary-color: #004684; //#224469;
$layout-border: 1px solid #ddd;

$base-text-color: #333;
$base-default-placeholder-text-color: #888;
$label-text-color: #666;

$header-background-color: #fff;
$header-link-color: #0072c6;
$header-service-link-color: #656565;

$navbar-height: 50px;
$sidebar-width: 300px;

$breadcrumb-panel: 32px;

$sidebar-show-toggler-size: 32px;

$footer-height: 0;
$footer-color: #3b434c;
$footer-text-color: #a2abb7;

$content-wrapper-color: #fff;

$button-color: #607D8B; //#0072c6;
$button-hover-color: #455A64; //#005b9d;
$button-text-color: #fff;

$grid-border: 1px solid #c9c9c9;
$nth-color: #f4f4f4;

$validation-color: red;

$page-layout-padding: 10px;
$window-layout-padding: 10px;

$h4-color: #455A64;

//breadcrumb
$breadcrumb-label-color: #eaeaea;
$breadcrumb-blue-gray: #34495e;
$breadcrumb-blue-gray-darken: #2c3e50;
$breadcrumb-blue: #455A64; //#66AFE3;//#3498db;
$breadcrumb-blue-darken: #455A64; //#2980b9;
$breadcrumb-green: #607D8B; //#1abc9c;
$breadcrumb-green-darken: #607D8B; //#16a085;
$breadcrumb-current-color: #FF9800; //#FF9000;//#ff6a00;